import { createServiceManifest } from '@estee/elc-service';

import { events } from '@estee/elc-service-bus';

import {
    CART_SIDEBAR_OFFERS_TAB,
    CART_SIDEBAR_CART_OFFERS,
    CART_SIDEBAR_OFFER_CODE_MODULE
} from '@estee/elc-service-view-names';

import { OffersService } from '~setup/OffersService';

const { name, version } = __serviceInfo__;
const {
    APPLY_OFFER_CODE,
    OFFER_CODE_SUCCEDED,
    OFFER_CODE_FAILED,
    OFFERS_LOADED,
    CART_UPDATED,
    CART_BADGE_CLICKED
} = events;

export const serviceQueries = [];
export const serviceViews = [
    CART_SIDEBAR_OFFERS_TAB,
    CART_SIDEBAR_CART_OFFERS,
    CART_SIDEBAR_OFFER_CODE_MODULE
];

export const serviceEvents = [
    APPLY_OFFER_CODE,
    OFFER_CODE_SUCCEDED,
    OFFER_CODE_FAILED,
    OFFERS_LOADED,
    CART_UPDATED,
    CART_BADGE_CLICKED
];

const manifestPayload = {
    queries: serviceQueries,
    events: serviceEvents,
    views: serviceViews,
    name,
    version
};
export const ServiceManifest = createServiceManifest<OffersService>(
    manifestPayload,
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'offersService'
);
