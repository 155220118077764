import { isVulcan } from '~utils/isVulcan';

if (process.env.VULCAN === '1') {
    try {
        const setup = require('~setup/vulcan/setup');
        setup.setupVulcan();
    } catch (e) {
        if (isVulcan()) {
            console.warn('Failed to initialize vulcan');
        }
    }
}
